import React from "react";
import {useRecoilValue} from "recoil";

import {authenticatedQuery} from "../store/session";
// @ts-ignore
import Grid from "./icons/Grid.svg";
// @ts-ignore
import User from "./icons/User.svg";
import Logout from "./icons/Logout.svg";
import Menu, {MenuItem} from "./Menu";


export default function MainMenu() {
  const authenticated = useRecoilValue(authenticatedQuery);

  return (<Menu>
    <MenuItem icon={<Grid/>} href="/videos">Categorieën</MenuItem>
    {!authenticated &&
    <MenuItem icon={<User/>} href="/auth/login">Mijn video&apos;s</MenuItem>}
    {authenticated &&
    <>
      <MenuItem icon={<User/>} href="/me/videos">Mijn video&apos;s</MenuItem>
      <MenuItem icon={<Logout/>} href="/auth/logout">Uitloggen</MenuItem>
    </>}
  </Menu>);
}
