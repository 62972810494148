import React from "react";
import Link from "next/link";

import styles from "../styles/Menu.module.scss";
import {className} from "../utils";

interface MenuItemProps {
  href: string;
  secondary?: boolean;
  icon?: React.ReactChild,
  children: React.ReactNode,
}

export function MenuItem({href, icon, children, secondary=false}: MenuItemProps) {
  const menuItemClassName = className(styles, "item", {
    item_secondary: secondary,
  });

  return (
    <li className={menuItemClassName}>
      <Link href={href} className={styles.link}>
        {icon && icon}
        <span className={styles.label}>{children}</span>
      </Link>
    </li>
  );
}

interface Props {
  children: React.ReactNode;
}

export default function Menu({children}: Props) {
  return (<div>
    <nav className={styles.menu}>
      <ul className={styles.list}>
        {children}
      </ul>
    </nav>
  </div>);
}
