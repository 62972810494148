import React from "react";
import Link from "next/link";

import styles from "../styles/Header.module.scss";

// @ts-ignore
import Logo from "./icons/Logo.svg";
import MainMenu from "./MainMenu";

export default function Header() {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Link href="/">
            <Logo/>
          </Link>
        </div>

        <MainMenu/>
      </div>
    </header>
  );
}
