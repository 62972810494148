import React from "react";
import Head from "next/head";

import Header from "./Header";
import Footer from "./Footer";
import SubscriptionBanner from "./SubscriptionBanner";

import {SITE_TITLE} from "../config";

interface Props {
  children: React.ReactNode;
}

export default function Layout({children}: Props) {
  return (<div>

    <Head>
      <title>{SITE_TITLE}</title>
    </Head>

    <Header/>

    <SubscriptionBanner/>

    {children}

    <Footer/>
  </div>);
}
