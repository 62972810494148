import React from "react";
import ReactModal from "react-modal";

import Button from "./Button";
import styles from "../styles/Modal.module.scss";

interface Action {
  label: string,
  handler?: () => void,
}

interface Props {
  ok: Action,
  cancel: Action,
  title: string,
  open: boolean,
  children: React.ReactNode,
}

export default function Modal({open, title, ok, cancel, children}: Props) {
  return <ReactModal
    className={styles.reactModal}
    isOpen={open}
  >
    <div className={styles.modal}>
      <div>
        <h2>{title}</h2>
        <div>{children}</div>
      </div>

      <div className={styles.actions}>
        <Button
          dense
          onClick={ok.handler}>{ok.label}</Button>
        <Button
          dense
          onClick={cancel.handler}>{cancel.label}</Button>
      </div>
    </div>
  </ReactModal>;
}
