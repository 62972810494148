import {useState} from "react";
import {useRecoilState} from "recoil";

import {usePayments} from "../context";
import subscription from "../store/subscription";
import Modal from "./Modal";
import Button from "./Button";
import styles from "../styles/SubscriptionBanner.module.scss";

export default function SubscriptionBanner() {
  const payments = usePayments();
  const [modalOpen, setModalOpen] = useState(false);
  const [hasSubscription, setHasSubscription] = useRecoilState(subscription);

  async function cancel() {
    setModalOpen(false);

    await payments.subscriptionDelete();
    setHasSubscription(false);
  }

  const showModal = () => setModalOpen(true);
  const hideModal = () => setModalOpen(false);

  if (!hasSubscription) {
    return <></>;
  }

  return <div className={styles.banner}>
    <Modal
      open={modalOpen}
      title="Abonnement annuleren"
      ok={{label: "Ja", handler: cancel}}
      cancel={{label: "Sluiten", handler: hideModal}}
    >
      Weet je zeker dat je jouw abonnement wil annuleren?
    </Modal>

    <p>Je bent momenteel geabonneerd op Hans Teeuwen World</p>

    <Button
      type="button"
      dense
      secondary
      onClick={showModal}
    >Annuleren</Button>
  </div>;
}
