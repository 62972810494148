import styles from "../styles/Footer.module.scss";
import MainMenu from "./MainMenu";
// @ts-ignore
import Logo from "./icons/Logo.svg";
// @ts-ignore
import Facebook from "./icons/Facebook.svg";
// @ts-ignore
import Instagram from "./icons/Instagram.svg";
// @ts-ignore
import Menu, {MenuItem} from "./Menu";

export default function Footer() {
  return (<footer className={styles.footer}>
    <div className={styles.container}>
      <div className={styles.left}>
        <Logo/>
        <MainMenu/>

        <div className={styles.share}>
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/hansteeuwen">
            <Facebook/>
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/therealhansteeuwen">
            <Instagram/>
          </a>
        </div>
      </div>

      <div className={styles.right}>
        <img src="/producties.png" alt="Producties logo" />

        <Menu>
          <MenuItem secondary href="/algemene-voorwaarden">Algemene voorwaarden</MenuItem>
          <MenuItem secondary href="/privacy-statement">Privacy statement</MenuItem>
        </Menu>
      </div>
    </div>
  </footer>);
}
